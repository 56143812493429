<div class="main-container">
  <div class="order-container">
    <div class="deliveries-container">
      <div class="title">
        <span id="deliveryNumber">ORDER NUMBER {{docNumber}}</span>
        <span>ID - {{orderId}}</span>
        <span>{{hospitalName}}</span>
        <h5>Deliveries</h5>
      </div>

      <div class="ordersText" *ngIf="dataSource.data.length === 0; else tableTemplate">
        <p>There are no deliveries for this hospital</p>
      </div>
    
      <ng-template #tableTemplate>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="deliveryName">
            <th mat-header-cell *matHeaderCellDef>DELIVERY NUMBER</th>
            <td mat-cell *matCellDef="let record" (click)="showMaterials(record.deliveryNumber)">{{ record.deliveryNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>DELIVERY STATUS</th>
            <td mat-cell *matCellDef="let record" (click)="showMaterials(record.deliveryNumber)">{{ record.deliveryStatus }}</td>
          </ng-container>
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef>OPTIONS</th>
            <td mat-cell *matCellDef="let record">
              <button mat-icon-button title="This option is not available at this time, for more information please contact customercarepr@bsci.com.">
                <mat-icon class="custom-icon">place</mat-icon>
              </button>
              <button mat-icon-button title="This option is not available at this time, for more information please contact customercarepr@bsci.com.">
                <mat-icon class="custom-icon">download</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let record; columns: displayedColumns"></tr>
          </ng-container>
        </table>
      </ng-template>
    
      <mat-paginator *ngIf="dataSource.data.length > 0" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </div>

    <div class="materials-container"  [ngClass]="{'visible': materialsTable}">
      <div class="title">
        <h5>Materials</h5>
        <span>DELIVERY NUMBER - {{ deliveryNumber }}</span>
      </div>

      <div class="ordersText" *ngIf="materialDataSource.data.length === 0; else materialsTableTemplate">
        <p>No materials found for this delivery.</p>
      </div>

      <ng-template #materialsTableTemplate>
        <table mat-table [dataSource]="materialDataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>MATERIAL CODE</th>
            <td mat-cell *matCellDef="let material">{{ material.code }}</td>
          </ng-container>
          <ng-container matColumnDef="materialDesc">
            <th mat-header-cell *matHeaderCellDef>MATERIAL DESC</th>
            <td mat-cell *matCellDef="let material">{{ material.materialDesc }}</td>
          </ng-container>
          <ng-container matColumnDef="deliveryQuantity">
            <th mat-header-cell *matHeaderCellDef>QUANTITY DELIVERED</th>
            <td mat-cell *matCellDef="let material">{{ material.deliveryQuantity }}</td>
          </ng-container>
          <ng-container matColumnDef="amountOrdered">
            <th mat-header-cell *matHeaderCellDef>QUANTITY ORDERED</th>
            <td mat-cell *matCellDef="let material">{{ material.amountOrdered }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedMaterialColumns"></tr>
          <tr mat-row *matRowDef="let material; columns: displayedMaterialColumns"></tr>
        </table>
      </ng-template>
    </div>
  </div>

  <button (click)="return()" id="returnButton">Return</button>


  <!-- <div class="delivery-information">
    <div class="title">
      <h4>PROCESO DE ENTREGA</h4>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="deliveryName">
        <th mat-header-cell *matHeaderCellDef>Numero de la entrega</th>
        <td mat-cell *matCellDef="let record">{{ record.deliveryName }}</td>
      </ng-container>
      
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Motivo del estado</th>
        <td mat-cell *matCellDef="let record">{{ record.status }}</td>
      </ng-container>
      
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>Opciones</th>
        <td mat-cell *matCellDef="let record">{{ record.options }}</td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let record; columns: displayedColumns"></tr>
    </table>
  </div> -->


</div>
