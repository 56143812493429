<div class="footer bg-footerGray position-fixed bottom-0 w-100 pb-3 no-margin">
  <div class="row d-flex align-items-center justify-content-center">
      <div class="col-md-4 commonTextSize borderBottom"> 
          <div class="d-flex align-items-center"> 
              <i class="bi bi-headset bi-headset-modify"></i>
              <span class="font-bostonBlue footerParagraph">If you have any questions, please <br> contact us here.</span>
          </div>
      </div>
  </div>
</div>
  

