import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeliveryService } from '../../services/deliveries.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonResponse } from 'src/app/models/http/response.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

interface DeliveryRecord {
  deliveryOrderId: number;
  deliveryNumber: string;
  deliveryStatus: string;
}

interface MaterialsRecord {
  code: number;
  materialDesc: string;
  deliveryQuantity: number;
  amountOrdered: number;
}

@Component({
  selector: 'app-edit-delivery',
  templateUrl: './edit-delivery.component.html',
  styleUrls: ['./edit-delivery.component.scss']
})
export class EditDeliveryComponent implements OnInit {

  readonly getDeliveriesObserver = {
    next: (data: CommonResponse<any>) => this.getDeliveriesNext(data),
    error: (error: any) => this.getDeliveriesError(error),
  };

  readonly getMaterialsObserver = {
    next: (data: CommonResponse<any>) => this.getMaterialsNext(data),
    error: (error: any) => this.getMaterialsError(error),
  };

  hospitalName: string | null = null;
  orderId: string | null = null;
  displayedColumns: string[] = ['deliveryName', 'status', 'options'];
  displayedMaterialColumns: string[] = ['code', 'materialDesc', 'deliveryQuantity', 'amountOrdered'];
  dataSource = new MatTableDataSource<DeliveryRecord>([]);
  materialDataSource = new MatTableDataSource<MaterialsRecord>([]);
  materialsTable: boolean = false;
  docNumber: string | null = null;
  deliveryNumber: number = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _router: Router,
    private _deliveryService: DeliveryService,
    private _route: ActivatedRoute,
    private _pageloadService: PageLoadingService,
    private _snackbarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      this.docNumber = params.get('docNumber');
    });

    this._deliveryService.getOrderDeliveries(this.docNumber)
      .subscribe(this.getDeliveriesObserver);
    this._pageloadService.showLoadingGif();
  }

  getDeliveriesNext(data: CommonResponse<any>) {
    const orderClient = data.body;

    if (Array.isArray(orderClient) && orderClient.length > 0) {
      const firstOrder = orderClient[0];

      this.docNumber = firstOrder.docNumber;
      this.hospitalName = firstOrder.client.soldName;
      this.orderId = firstOrder.client.soldToCustomerNumber;

      const deliveries = firstOrder.deliveries;

      this.dataSource.data = deliveries.map((delivery: { deliveryOrderId: any; deliveryNumber: any; deliveryStatus: any; }) => ({
        deliveryOrderId: delivery.deliveryOrderId,
        deliveryNumber: delivery.deliveryNumber,
        deliveryStatus: delivery.deliveryStatus
      }));

    } else {
      this._snackbarService.openSnackBar('No order data was found.');
    }

    this._pageloadService.hideLoadingGif();
  }

  getDeliveriesError(error: any) {
    this._snackbarService.openSnackBar("There are no orders for this hospital");
    this._pageloadService.hideLoadingGif();
    console.error('Error trying to obtain orders', error);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showMaterials(deliveryNumber: number) {
    this._pageloadService.showLoadingGif();
    this.deliveryNumber = deliveryNumber;
    this._deliveryService.getMaterials(this.deliveryNumber)
      .subscribe(this.getMaterialsObserver);
  }

  getMaterialsNext(data: CommonResponse<any>) {
    const delivery = data.body[0];

    if (delivery && delivery.materials && delivery.materials.length > 0) {
      this.materialDataSource.data = delivery.materials.map((material: any) => ({
        code: material.materialCode,
        materialDesc: material.materialDescription,
        deliveryQuantity: material.materialDelivered,
        amountOrdered: material.materialShipped
      }));
    }

    this._pageloadService.hideLoadingGif();
    this.materialsTable = true;
  }

  getMaterialsError(error: CommonResponse<any>) {
    this._snackbarService.openSnackBar("There are no materials for this hospital");
    this._pageloadService.hideLoadingGif();
    console.error('Error trying to obtain materials:', error);
  }

  return() {
    this._router.navigate(['/orders']);
  }
}
