<div class="search-container d-flex flex-column mb-3">
  <div class="d-flex align-items-center justify-content-between">
  <div class="d-flex">
    <mat-form-field appearance="fill" class="flex-grow-1 me-2">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="searchTextMainFilter" (ngModelChange)="applySearchMainFilter()">
    </mat-form-field>

    <div class="advancedSearch">
      <a class="advancedSearchMain" data-bs-toggle="collapse" href="#filterCollapse" role="button" aria-expanded="false" aria-controls="filterCollapse">
        Advanced options
      </a>
    </div>
  </div>
  <app-data-export class="row positionButtonDataExport" (click) = "exportExcel()"> </app-data-export>
  </div>

  <div class="collapse" id="filterCollapse">
    <div class="d-flex mt-2">
      <mat-form-field appearance="fill" class="flex-grow-1 me-2">
        <mat-label>Client Number</mat-label>
        <input matInput [formControl]="filters[0].control" (input)="applySearchFilter()">
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-grow-1 me-2">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="filters[1].control" (selectionChange)="applySearchFilter()">
          <mat-option value="">None</mat-option>
          <mat-option *ngFor="let option of filters[1].validValues" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-grow-1 me-2">
        <mat-label>Creation Date</mat-label>
        <input matInput [formControl]="filters[2].control" (dateChange)="applySearchFilter()" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <a class="advancedSearchMain" role="button" aria-expanded="false" aria-controls="filterCollapse" (click)="cleanFilter()">
        Clean
      </a>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="registers" class="mat-elevation-z8">
      <ng-container matColumnDef="soldToCustomerNumer">           
        <th mat-header-cell *matHeaderCellDef>CLIENT NUMBER</th>
        <td mat-cell *matCellDef="let record">{{ record.soldToCustomerNumer }}</td>          
      </ng-container>
      <ng-container matColumnDef="soldName">           
        <th mat-header-cell *matHeaderCellDef>NAME</th>
        <td mat-cell *matCellDef="let record">{{ record.soldName }}</td>
      </ng-container>
      <ng-container matColumnDef="docNumber">           
        <th mat-header-cell *matHeaderCellDef>ORDER NUMBER</th>
        <td mat-cell *matCellDef="let record">{{ record.docNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="overallStat">           
        <th mat-header-cell *matHeaderCellDef>STATUS</th>
        <td mat-cell *matCellDef="let record">{{ record.overallStat }}</td>
      </ng-container>
      <ng-container matColumnDef="creationDate">           
        <th mat-header-cell *matHeaderCellDef>CREATION DATE</th>
        <td mat-cell *matCellDef="let record">{{ record.creationDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="deliveryDetails">
        <th mat-header-cell *matHeaderCellDef>ACTION</th>
        <td mat-cell *matCellDef="let record">
          <button mat-button (click)="showDetails(record.docNumber)"><i class="bi bi-eye" style="font-size: 1.3rem;"></i></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let record; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
